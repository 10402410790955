import { v4 } from 'uuid';

export enum AcitveLoginType {
    PARTICLE = 'particle',
    PRIVATE_KEY = 'private_key',
    METAMASK = 'metamask',
    RAINBOW = 'rainbow',
    TRUST = 'trust',
    IM_TOKEN = 'im_token',
    BIT_KEEP = 'bit_keep',
    PHANTOM = 'phantom',
    OTHER = 'other',
}

export enum ActiveAction {
    SIGN = 'sign',
    LOGIN = 'login',
    OPEN = 'open',
    OPEN_WALLET = 'open_wallet',
}
export interface BiOptions {
    sdk_api_domain: string;
    device_id: string;
    sdk_version: string;
    project_config: {
        project_uuid: string;
        project_key: string;
        project_app_uuid: string;
    };
}

export interface SendActiveParams {
    chain_id: number;
    identity: string;
    login_type: AcitveLoginType;
    action: ActiveAction;
    wallet_address: string;
    user_info?: string;
}

export enum RecordType {
    PAGE_LOGIN_BUTTON_CLICK = 'page_login_button_click', // 登录按钮点击
    PAGE_LOGIN_BUTTON_CLICK_SUCCESS = 'page_login_button_click_success', // 登录成功
    PAGE_LOGIN_BUTTON_CLICK_FAILURE = 'page_login_button_click_failure', // 登录失败
    PAGE_LOGIN_SUCCESS_BACK = 'page_login_success_back', // 登录成功后返回app
    PAGE_SIGN_CONFIRM_BUTTON_CLICK = 'page_sign_confirm_button_click', // confirm按钮点击
    PAGE_SIGN_CONFIRM_BUTTON_CLICK_SUCCESS = 'page_sign_confirm_button_click_success', // confirm成功
    PAGE_SIGN_CONFIRM_BUTTON_CLICK_FAILURE = 'page_sign_confirm_button_click_failure', // confirm失败
    PAGE_SETTING_MASTER_PASSWORD_ENTER = 'page_setting_master_password_enter', // 进入主密码设置页面
    PAGE_SETTING_MASTER_PASSWORD_SET = 'page_setting_master_password_set', // 主密码设置成功
    PAGE_SETTING_MASTER_PASSWORD_CHANGE = 'page_setting_master_password_change', // 主密码修改成功
    PAGE_MASTER_PASSWORD_VERIFY = 'page_master_password_verify', // 主密码验证
    PAGE_MASTER_PASSWORD_VERIFY_SUCCESS = 'page_master_password_verify_success', // 主密码验证成功
}
export interface SendRecordsParams {
    record_type: RecordType;
    reason?: string;
}

export class BI {
    private options: BiOptions;

    constructor(options: BiOptions) {
        this.options = options;
    }

    active(params: SendActiveParams) {
        const timestamp: number = Math.round(new Date().getTime() / 1000);
        const randomStr: string = v4();
        const { project_uuid, project_key, project_app_uuid } = this.options.project_config;

        const queryParams = {
            timestamp,
            random_str: randomStr,
            project_app_uuid: project_app_uuid,
            projectUuid: project_uuid,
            projectKey: project_key,
        };

        let url = this.options.sdk_api_domain + '/active?';
        Object.keys(queryParams).forEach((key) => {
            //  @ts-ignore
            url += `${key}=${encodeURI(queryParams[key])}&`;
        });
        url = url.slice(0, -1);

        navigator.sendBeacon(
            url,
            new Blob([new URLSearchParams(params as any).toString()], {
                type: 'application/x-www-form-urlencoded',
            })
        );
    }

    records(params: SendRecordsParams) {
        try {
            console.log('>>>>>send records', params);
            const timestamp: number = Math.round(new Date().getTime() / 1000);
            const randomStr: string = v4();
            const { project_uuid, project_key, project_app_uuid } = this.options.project_config;

            const queryParams = {
                timestamp,
                random_str: randomStr,
                project_app_uuid: project_app_uuid,
                projectUuid: project_uuid,
                projectKey: project_key,
            };

            let url = this.options.sdk_api_domain + '/records?';
            Object.keys(queryParams).forEach((key) => {
                //  @ts-ignore
                url += `${key}=${encodeURI(queryParams[key])}&`;
            });
            url = url.slice(0, -1);

            navigator.sendBeacon(
                url,
                new Blob([new URLSearchParams(params as any).toString()], {
                    type: 'application/x-www-form-urlencoded',
                })
            );
        } catch (error) {
            console.log(error);
        }
    }
}
